<template>
  <div>
    <a-modal
      width="800px"
      v-model="isShow"
      centered
      :mask-closable="false"
      title="关联客户"
    >
      <template slot="footer">
        <a-button class="ant-btn-primary" @click="closeModal">
          关闭
        </a-button>
      </template>

      <a-table
        size="middle"
        :columns="columns"
        :data-source="data"
        :loading="loading"
        :scroll="{ y: 350 }"
        :pagination="false"
        row-key="id"
      >
        <span slot="actions" slot-scope="text, record">
          <a-space>
            <a @click="switchUser(record.id)" v-if="record.effective">登录</a>
          </a-space>
        </span>
      </a-table>

      <a-row class="count-tips">
        共 {{ data.length }} 条记录
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { findBaseProductAgents } from '@/api/base_product'
import { switchUser } from '@/api/switch_user'
import { getOriginalUserUid } from '@/utils/token'
import { formatBigNumber } from '@/utils/filter'

export default {
  name: 'ProductAgents',
  props: {
    id: {
      type: Number,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      data: [],
      loading: true
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },

    columns() {
      return [
        {
          title: '客户名称',
          dataIndex: 'name'
        },
        {
          title: '客户类型',
          dataIndex: 'agent_type'
        },
        {
          title: '卡号总数',
          dataIndex: 'card_count',
          customRender: formatBigNumber
        },
        {
          title: '操作',
          dataIndex: 'actions',
          width: 100,
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    closeModal() {
      this.isShow = false
    },

    fetchData() {
      this.loading = true
      findBaseProductAgents(this.id).then((res) => {
        this.data = res.data
        this.loading = false
      })
    },

    switchUser(id) {
      switchUser({ agent_id: id, original_user_uid: getOriginalUserUid() }).then((res) => {
        if (res.code === 0) {
          location.href = res.data.redirect_url
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.count-tips {
  padding-top: 20px;
}
</style>
